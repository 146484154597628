import React, { useState, useEffect, useRef } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { 
  ThemeProvider, 
  createTheme, 
  CssBaseline,
  Container,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  CircularProgress,
  Alert,
  Paper
} from '@mui/material';
import { Language, Translate, CloudUpload } from '@mui/icons-material';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const LANGUAGES = [
  { name: "English", code: "en" },
  { name: "Spanish", code: "es" },
  { name: "French", code: "fr" },
  { name: "German", code: "de" },
  { name: "Italian", code: "it" },
  { name: "Portuguese", code: "pt" },
  { name: "Russian", code: "ru" },
  { name: "Chinese (Simplified)", code: "zh-CN" },
  { name: "Japanese", code: "ja" },
  { name: "Korean", code: "ko" },
];

const PROVIDERS = [
  { name: "Google Translate", value: 'google' },
  { name: "DeepL", value: 'deepl' }
];

const theme = createTheme({
  palette: {
    primary: {
      main: '#1a73e8',
    },
    secondary: {
      main: '#fbbc04',
    },
  },
});

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const [targetLang, setTargetLang] = useState(LANGUAGES[0].code);
  const [provider, setProvider] = useState(PROVIDERS[0].value);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (token && storedUser) {
      setIsLoggedIn(true);
      setUser(storedUser);
    }
  }, []);

  const handleLogin = async (credentialResponse) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/google`, {
        token: credentialResponse.credential
      });
      const userData = res.data.user;
      setUser(userData);
      setIsLoggedIn(true);
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('user', JSON.stringify(userData));
    } catch (error) {
      console.error('Login failed:', error);
      setError('Login failed. Please try again.');
    }
  }


  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setIsLoggedIn(false);
    setUser(null);
  }

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleTranslate = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('target_lang', targetLang);
      formData.append('provider', provider);

      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/translate`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        responseType: 'blob'
      });

      const blob = new Blob([res.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = "translated_document.pdf";
      link.click();
      URL.revokeObjectURL(link.href);
      
      // Clear the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      setFile(null);
    } catch (error) {
      console.error('Translation failed:', error);
      setError('Translation failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="sm">
          <Box sx={{ my: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Document-AI PDF Translator v0.2
            </Typography>
            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
            {!isLoggedIn ? (
              <GoogleLogin
                onSuccess={handleLogin}
                onError={() => setError('Login Failed')}
                useOneTap
                flow="implicit"
                auto_select
                context="use"              />
            ) : (
              <Paper elevation={3} sx={{ p: 3 }}>
                <Typography variant="body1" gutterBottom>
                  Welcome, {user?.name}
                </Typography>
                <Button variant="outlined" onClick={handleLogout} sx={{ mb: 2 }}>
                  Logout
                </Button>
                <Box component="form" noValidate autoComplete="off">
                  <input
                    accept="application/pdf"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                  <label htmlFor="raised-button-file">
                    <Button variant="contained" component="span" startIcon={<CloudUpload />} fullWidth sx={{ mb: 2 }}>
                      {file ? file.name : 'Upload PDF'}
                    </Button>
                  </label>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="target-language-label" sx={{ bgcolor: 'background.paper', px: 1 }}>Target Language</InputLabel>
                    <Select
                      labelId="target-language-label"
                      value={targetLang}
                      onChange={(e) => setTargetLang(e.target.value)}
                      startAdornment={<Language sx={{ mr: 1 }} />}
                      sx={{ mt: 1 }}
                    >
                      {LANGUAGES.map((lang) => (
                        <MenuItem key={lang.code} value={lang.code}>{lang.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="provider-label" sx={{ bgcolor: 'background.paper', px: 1 }}>Translation Provider</InputLabel>
                    <Select
                      labelId="provider-label"
                      value={provider}
                      onChange={(e) => setProvider(e.target.value)}
                      startAdornment={<Translate sx={{ mr: 1 }} />}
                      sx={{ mt: 1 }}
                    >
                      {PROVIDERS.map((p) => (
                        <MenuItem key={p.value} value={p.value}>{p.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button 
                    variant="contained" 
                    onClick={handleTranslate} 
                    disabled={!file || isLoading} 
                    fullWidth
                    startIcon={isLoading ? <CircularProgress size={20} /> : <Translate />}
                  >
                    {isLoading ? 'Translating...' : 'Translate'}
                  </Button>
                </Box>
              </Paper>
            )}
          </Box>
        </Container>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}


export default App;